<template>
  <div class="container">
    <div class="my-info-container pt-50 pb-50 col-sm-12 col-xl-10">
      <div class="user-avatar-img-wrapper">
        <img src="../assets/img/my-info-img.png" alt="IMG" />
      </div>
      <div class="user-info">
        <personal-info />
        <BaseButton class="logout" @click="isLogoutModal = true">
          Log Out
        </BaseButton>
      </div>
    </div>
  </div>

  <base-modal v-if="isLogoutModal">
    <p class="modal-title">Are you sure want to logout?</p>
    <div class="button-wrap">
      <BaseButton class="btn primary" @click="clearUserData"
        >Yes, Logout</BaseButton
      >
      <BaseButton class="btn error" @click="isLogoutModal = false"
        >Cancel</BaseButton
      >
    </div>
  </base-modal>
</template>
<script>
import { defineComponent, ref } from "vue";
import PersonalInfo from "./PersonalInfo.vue";
import { userLogout } from "@/API/API-user";
import BaseButton from "@/components/base/BaseButton";
import router from "@/router";
import {useUserStore} from "@/store/user";


export default defineComponent({
  components: { BaseButton, PersonalInfo },
  setup() {
    const userStore = useUserStore()
    let isLogoutModal = ref(false);

    /**
     * Clear user data leftovers after logout
     */
    const clearUserData = () => {
      userLogout().then(r=> {
        if (r.data.status === "success") {
          userStore.setUserData({})
          localStorage.clear()
          router.push({ name: "TheLogin" });
        }
      })
    }
    return {
      clearUserData,
      isLogoutModal,
    };
  },
});
</script>

<style scoped lang="scss">
.my-info-container {
  display: flex;
  align-items: center;
  gap: 100px;
  height: 100%;
  margin: 0 auto;

  @media (max-width: 1200px) {
    gap: 60px;
    padding: 80px 32px 16px;
    align-items: start;
  }

  @media (max-width: 650px) {
    padding: 40px 32px 16px;
  }

  @media (max-width: 650px) {
    justify-content: center;
  }
}

.user-avatar-img-wrapper {
  overflow: hidden;
  border-radius: 50%;
  height: max-content;

  @media (max-width: 1000px) {
    display: none;
  }
}

img {
  transform: scale(1.1);
}

.user-info {
  min-height: 470px;

  @media (max-width: 650px) {
    .logout {
      margin: 0 auto;
    }
  }
}

.modal-title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.button-wrap {
  padding: 20px 0 0;
  display: flex;
  gap: 20px;

  @media (max-width: 450px) {
    justify-content: center;
  }
}
</style>