<template>
  <notifications position="top center" width="300px" />
  <router-view></router-view>
  <waiting-window v-if="!meetStore.getIsMeetInited" />
</template>
<script>
import {defineComponent, onMounted, provide, ref} from "vue";
import WaitingWindow from "@/modules/hra/components/WaitingWindow"
import {useSockets} from "@/modules/hra/utils/use-sockets";
import {useMeetStore} from "@/store/meet";
import {useHraStore} from "@/modules/hra/store/hra";
import {useRouter} from "vue-router";
import {useWorkingSessionStore} from "@/store/workingSession";
import {notify} from "@kyvg/vue3-notification";

export default defineComponent({
  components: { WaitingWindow },
  setup() {
    const workingSessionStore = useWorkingSessionStore()
    const meetStore = useMeetStore();
    const hraStore = useHraStore();
    const router = useRouter();
    // SOCKETS
    const Socket = ref(null);
    const isSocketReady = ref(true);

    onMounted(() => {
      const callbacks = {
        onclose: () => {
          isSocketReady.value = false
        },
        onmessage: (e) => {
          let data = JSON.parse(e.data).data;
          let action = JSON.parse(e.data).action;
          switch (action) {
            case "inited":
              isSocketReady.value = true
              break;
            case "call_request":
              meetStore.setPatientData(data.patient);
              meetStore.setRinging(true);
              break;
            case "call_info":
              meetStore.setPatientData(data.patient);
              meetStore.setRoomSettings(data.chat.roomName)
              router.push({ name: "HRAForm" });
              break;
            case "call_update":
              hraStore.setState(data);
              break;
            case "call_close":
              meetStore.setRoomClear();
              router.push({ name: "WaitingPage" });
              break;
            case "working_time":
              workingSessionStore.setWorkingTimeHistory(data.active, data.month)
              break;
            case "calls_count":
              workingSessionStore.setCallsHistory(data.active, data.month)
              break;
            case "session_info":
              workingSessionStore.setActive(data.WorkingSession.isActive)
              workingSessionStore.setPaused(data.WorkingSession.isPaused)
              workingSessionStore.setScheduleId(data.WorkingSession.scheduleId)
              break;
            case "info":
              notify({
                title: `${data.message}`
              });
              if(data.status === 'error') Socket.value.isClosed = true
              break;
          }
        }
      }
      Socket.value = useSockets(callbacks);
      Socket.value.connect()
    });

    provide('Socket', Socket)
    provide('isSocketReady', isSocketReady)

    return { isSocketReady,meetStore }
  }
});
</script>