<template>
  <DataTable
    :table-class-name="'history-table'"
    :header-class-name="'history-header'"
    :header-item-class-name="'history-header-item'"
    :body-row-class-name="'history-body-row'"
    :body-item-class-name="'history-body-item'"
    :headers="props.dataHeaders"
    :items="props.dataItems"
    :rows-per-page="5"
    :rows-items="[5, 10, 15]"
    buttons-pagination
  >
    <template #item-status="{ status }">
      <div >
        <p :class="status === 'confirm' ? 'history-table-blue-text' : 'history-table-red-text'">{{ status }}</p>
      </div>
    </template>
  </DataTable>
</template>
<script>
import { defineComponent } from "vue";
import PaginationArrowLeft from "../assets/img/pagination-arrow-left.svg";
import PaginationArrowRight from "../assets/img/pagination-arrow-right.svg";
export default defineComponent({
  props: {
    dataHeaders: Object,
    dataItems: Object,
  },
  setup(props) {
    return {
      props,
      PaginationArrowRight,
      PaginationArrowLeft,
    };
  },
});
</script>
<style lang="scss">
.history-table {
  border: none !important;
}
.history-table-red-text {
  color: #FF0000;
}
.history-table-blue-text {
  color: #29ABE2;
}

.history-header-item {
  background-color: #F9F9F9 !important;
  color: #000 !important;
  font-size: 18px;
  font-weight: 600;
  padding: 20px !important;
  border: none !important;
&:first-child{
   border-top-left-radius: 12px !important;
   border-bottom-left-radius: 12px !important;
 }
&:last-child{
   border-top-right-radius: 12px !important;
   border-bottom-right-radius: 12px !important;
 }
}


.history-body-item {
  font-size: 18px;
  padding: 20px !important;
}

//.history-pagination-btn {
//  cursor: pointer;
//  width: 32px;
//  height: 32px;
//  border: 1px solid #29ABE2;
//  border-radius: 7px;
//&.disabled {
//   cursor: default;
//   border: 1px solid red;
//   background-color: #F1F1F1;
// }
//}

.selected {
  background-color: #29ABE2 !important;
}

.vue3-easy-data-table__footer {
  padding-top: 50px !important;
  justify-content: center !important;
}

.previous-page__click-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px;
  width: 36px;
  height: 36px;
  border: 1px solid #29ABE2;
  border-radius: 7px;
&.first-page {
   border: 1px solid #737373;
   background-color: #f1f1f1;

 }
}
.next-page__click-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px;
  width: 36px;
  height: 36px;
  border: 1px solid #29ABE2;
  border-radius: 7px;
&.last-page {
   border: 1px solid #737373;
   background-color: #f1f1f1;

 }
}
.arrow.arrow-right {
  border-color: #29ABE2 !important;
  z-index: 99999;
}
.arrow.arrow-left {
  border-color: #29ABE2 !important
}

.buttons-pagination .item.button {
  background-color: transparent !important;
  font-size: 14px !important;
  color: #000 !important;
  border-radius: 50%;
  border: none !important;
}

.buttons-pagination .item.omission {

  border: none !important;
}
.buttons-pagination .item.button.active {
  color: #29ABE2 !important;

}
</style>