<template>
  <input
    v-model="code"
    type="text"
    :placeholder="props.placeholder"
    :class="props.error ? 'error' : ''"
    @input="setInputValue"
  />
</template>
<script>
import {defineComponent, ref} from "vue";

export default defineComponent({
  emits: ['value'],
  props: {
    placeholder: String,
    error: Boolean,
  },

  setup(props, {emit}) {
    const code = ref('')
    // const emit = defineEmits(['value'])

    /**
     * Send input data to parent component
     */
    const setInputValue = () => {
      emit('value', code.value)

    }
    return {
      props,
      code,
      setInputValue
    };
  },
});
</script>
<style scoped lang="scss">
.input {
  display: block;
  height: 3.5rem !important;
  background-color: var(--color-light-gray);
  border-radius: 0.75rem;
  color: var(--color-gray-text);
  padding: 0.9375rem;

  &.login {
    width: 24.188rem;
    border: none;
  }

  &::placeholder {
    color: var(--color-gray-text);
  }

  &:focus {
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
  }

  &.error {
    border: 1px solid var(--color-red);
    color: var(--color-red);
  }
}


// ======================

.radio-container {
  display: block;
  position: relative;
  padding-left: 2.188rem;
  cursor: pointer;
  font-size: 1.375rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.563rem;
  width: 1.563rem;
  background-color: #fff;
  border: 1px solid var(--color-primary);
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: #fff;
  border: 1px solid var(--color-primary);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 0.438rem;
  left: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: var(--color-primary);
}
</style>