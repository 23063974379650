<template>

  <div class="d-flex wrapper">

    <p class="text-app">Get App</p>

    <img class="get-app-img" :src="GetAppIMG" alt="GetAppIMG">

    <div class="span-two">
      <base-button-app-store class="btn appstore" :text1="'Download on the'" :text2="'App Store'" :image="0"/>
      <base-button-app-store class="btn appstore" :text1="'Get it on'" :text2="'Google Play'" :image="1"/>
    </div>

  </div>

</template>

<script>

import { defineComponent } from "vue";
import BaseButtonAppStore from "@/components/base/BaseButtonAppStore";
import GetAppIMG from '../../../assets/images/get-app.png'

export default defineComponent({
  components: {BaseButtonAppStore},
  setup() {
    return {
      GetAppIMG
    };
  },
});

</script>

<style scoped lang="scss">

.wrapper {
  background-color: #f5f5f5;
  padding: 80px 0;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.text-app {
  font-style: normal;
  font-weight: 300;
  font-size: 75px;
  line-height: 90px;
  text-align: center;
  color: #000000;
}

.span-two {
  width: 400px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px;
}

@media (max-width: 576px) {
  .get-app-img {
    width: 200px;
  }

  .appstore {
    margin: 0 auto;
    width: 200px;
  }

}

@media (max-width: 545px) {
  .span-two {
    padding: 20px 0 0;
    grid-template-columns: auto;
  }

  .text-app {
    font-size: 40px;
    line-height: 50px;
  }
}

</style>