import BaseText from "@/components/base/BaseText";
import BaseTelInput from "@/components/base/BaseTelInput";
import BaseSelect from "@/components/base/BaseSelect";
import BaseCheckbox from "@/components/base/BaseCheckbox";
import BaseLoader from "@/components/base/BaseLoader";
import BaseButton from "@/components/base/BaseButton";
import BaseInput from "@/components/base/BaseInput";
import BaseModal from "@/components/base/BaseModal";
import BaseRoundChart from "@/components/base/BaseRoundChart.vue";

export default [
    {
        name: "BaseText",
        component: BaseText,
    },
    {
        name: "BaseTelInput",
        component: BaseTelInput,
    },
    {
        name: "BaseSelect",
        component: BaseSelect,
    },
    {
        name: "BaseCheckbox",
        component: BaseCheckbox,
    },
    {
        name: "BaseLoader",
        component: BaseLoader,
    },
    {
        name: "BaseButton",
        component: BaseButton,
    },{
        name: "BaseInput",
        component: BaseInput,
    },
    {
        name: "BaseModal",
        component: BaseModal,
    },
    {
        name: "BaseRoundChart",
        component: BaseRoundChart,
    },
]