<template>
  <button>
    <div class="grid-three">
      <img :src="image === 0 ? AndroidSVG : AppStoreSVG" alt="img">
      <hr class="hr-custom">
      <div class="text-grid">{{text1}}<p class="bold">{{text2}}</p></div>
    </div>
  </button>
</template>

<script>
import { defineComponent } from "vue";
import AndroidSVG from "../../assets/images/svg/android.svg"
import AppStoreSVG from "../../assets/images/svg/apple.svg"

export default defineComponent({
  props: {
    text1: String,
    text2: String,
    image: Number
  },
  setup() {
    return {
      AndroidSVG,
      AppStoreSVG
    };
  },
});
</script>

<style scoped lang="scss">
.btn {
  cursor: pointer;
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  line-height: 0.75rem;
  border-radius: 0.75rem;
  padding: 1rem;
  transition: 0.4s;
  background: #FFFFFF;
}

.btn:hover {
  background: #595858
}

.btn:hover .text-grid, .btn:hover .bold {
  color: #FFFFFF;
}

.appstore {
  border: 0.8px solid #D0D0D0;
  border-radius: 10px;
}

.bold {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  color: #000000;
}

.text-grid {
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.hr-custom {
  border-left: 1px solid #E1E1E1;
  height: 30px;
}

.grid-three {
  display: grid;
  gap: 10px;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(3, auto);
}

</style>