<template>
  <div class="ta-c pt-30 pb-30 bb">
    <p class="title">Our Partners</p>
    <ul class="mt-40">
      <li v-for="(partner, index) in ourPartnersList" :key="index">
        <img :src="partner.logo" :alt="partner.name" />
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ourPartnersList from "../services/out-partners";
export default defineComponent({
  setup() {
    return { ourPartnersList };
  },
});
</script>
<style scoped lang="scss">
.title {
  font-size: 75px;
}
</style>