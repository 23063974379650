<template>
  <div id="jitsi-meet-container"></div>
</template>

<script>
import { defineComponent, watchEffect } from "vue";

import createRoom from "../utils/create-room.js";
import { useMeetStore } from "@/store/meet";
import { notify } from "@kyvg/vue3-notification";

export default defineComponent({
  setup() {
    const meetStore = useMeetStore();
    const onload = () => {
      notify({
        title: "Connected, say hello to the patient",
      });
      // context.emit("load", e);
      // const emit = defineEmits("connected", e);
    };

    watchEffect(() => {
      if (meetStore.getIsMeetInited) {
        console.log("asdasd");
        createRoom(onload);
      }
    });
    return;
  },
});
</script>