<template>
  <my-info />
</template>
<script>
import { defineComponent, onMounted, inject } from "vue";
import MyInfo from "@/modules/user/components/MyInfo.vue";


export default defineComponent({
  components: { MyInfo },

  setup() {
    const Socket = inject('Socket')

    onMounted(() => {
      console.log(Socket);
    })
  }
});
</script>
<style lang="scss">
.container-own {
  max-width: 1366px;
  margin: 0 auto;
  height: 100%;
}
</style>