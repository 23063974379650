<template>
  <div class = "d-flex p-20 col-8">
    <ul class = "d-flex f-wrap col-6">
      <li
        class = "our-contacts-item-text b-i-block mb-10 col-6"
        v-for = "(contact, index) in ourContactsList"
        :key = "index"
      >
        <a :href = "contact.link" class = "link">{{ contact.text }}</a>
      </li>
    </ul>
    <base-button class = "h btn primary col-4">
      <span class = "our-contacts-item-text">
        Login for employees</span
      ></base-button
    >
  </div>
</template>
<script>
import {defineComponent} from "vue";
import ourContactsList from "../services/our-contacts";

export default defineComponent({
  setup() {
    return { ourContactsList };
  },
});
</script>
<style lang = "scss">
.our-contacts-item-text {
  font-size: 18px;
  font-weight: 500;
}

.h {
  height: 60px
}
</style>