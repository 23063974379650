<template>
  <div>
    <div class="dashboard-wrap container" style="position: relative">
      <template v-if="!isSocketReady">
        <div class="loader-wrap d-flex justify-content-center">
          <p class="waiting-text mr-5">Connecting</p>
          <pulse-loader :loading="true" :color="'#47b6e4'" class="pulse-loader"></pulse-loader>
        </div>
      </template>
      <template v-else>
        <div class="row mb-5">
          <div class="col-xxl-5 col-xl-5 col-sm-8 user-name-wrap">
            <p class="user-name">Hello, {{ userStore.name }}!</p>
            <div v-if="!workingSessionStore.isActive">
              <base-button class="btn start" style="width: 100%;" @click="isStartModal = true">Punch In</base-button>
            </div>
            <div v-if="workingSessionStore.isActive" class="button-group">
              <template v-if="workingSessionStore.isPaused">
                <base-button class="btn primary mr-15" @click="workingSessionUnpause()">Unpause</base-button>
              </template>
              <template v-if="!workingSessionStore.isPaused">
                <base-button class="btn primary mr-15" @click="workingSessionPause()">Pause</base-button>
              </template>
              <base-button class="btn danger" @click="isStopModal = true">Punch Out</base-button>
            </div>
          </div>
          <div
              class="col-xxl-6 col-xl-7 col-lg-8 col-xs-12 col-sm-12 col-ms-12 offset-xxl-1 offset-xl-0 chart-wrap mt-5">
            <div class="wrapper-chart">
              <p class="text-chart">Total time for day</p>
              <div class="d-flex">
                <base-round-chart
                    class="round"
                    :color="'#29ABE2'"
                    :label="'HOURS'"
                    :value="workingSessionStore.getWorkingTimeHistoryToday.hours"
                    :width="width > chartMedia ? 90 : 50"
                />
                <base-round-chart
                    class="round"
                    :color="'#29ABE2'"
                    :label="'MINUTES'"
                    :value="workingSessionStore.getWorkingTimeHistoryToday.minutes"
                    :width="width > chartMedia ? 90 : 50"
                />
                <base-round-chart
                    class="round"
                    :color="'#29ABE2'"
                    :label="'CALLS'"
                    :value="workingSessionStore.getCallsHistoryToday"
                    :width="width > chartMedia ? 90 : 50"
                />
              </div>
            </div>
            <div class="wrapper-chart">
              <p class="text-chart">Total time per month</p>
              <div class="d-flex">
                <base-round-chart
                    class="round"
                    :color="'#20B472'"
                    :label="'HOURS'"
                    :value="workingSessionStore.getWorkingTimeHistoryMonth.hours"
                    :width="width > chartMedia ? 90 : 50"
                />
                <base-round-chart
                    class="round"
                    :color="'#20B472'"
                    :label="'MINUTES'"
                    :value="workingSessionStore.getWorkingTimeHistoryMonth.minutes"
                    :width="width > chartMedia ? 90 : 50"
                />
                <base-round-chart
                    class="round"
                    :color="'#20B472'"
                    :label="'CALLS'"
                    :value="workingSessionStore.getCallsHistoryMonth"
                    :width="width > chartMedia ? 90 : 50"
                />
              </div>
            </div>
          </div>
        </div>


        <div class="no-calls-text" v-if="workingSessionStore.isActive && todayCallHistory.length === 0">
          No calls yet today
        </div>


        <div v-if="workingSessionStore.isActive && todayCallHistory.length > 0"
        class="history-table-container"
        >

          <history-table
          :dataHeaders="tableHeaders"
          :dataItems="todayCallHistory"
          />
        </div>


<!--        <div v-if="!workingSessionStore.isActive">-->
<!--          <p class="waiting-text">Click "Punch In" to start session</p>-->
<!--        </div>-->
      </template>
    </div>
    <base-modal v-if="isStartModal" @close="isStartModal = false">
      <p class="text-sure">Are you sure want to start working?</p>
      <div class="span-two">
        <base-button class="btn primary" @click="workingSessionStart">Start</base-button>
        <base-button class="btn danger" @click="isStartModal = false">Cancel</base-button>
      </div>
    </base-modal>
    <base-modal v-if="isStopModal" @close="isStopModal = false">
      <p class="text-sure">Are you sure want to stop work?</p>
      <div class="span-two">
        <base-button class="btn primary" @click="[workingSessionStop(), isStopModal = false]">Stop work</base-button>
        <base-button class="btn error" @click="isStopModal = false">Cancel</base-button>
      </div>
    </base-modal>
    <base-modal v-if="meetStore.isRinging" :triggered="true" :callAlarm="callAlarm">
      <div class="ta-c">
        <p>Call from</p>
        <p class="patient-name-text">{{ meetStore.patient.name }}</p>
        <p>{{ meetStore.patient.sex }} {{ meetStore.patient.yo }}</p>
        <div class="call-modal-btn-wrap">
          <base-button
              class="btn success"
              @click="onAcceptPatientBtnClick"
          >Accept
          </base-button>
          <base-button
              class="btn e"
              @click="onDeclinePatientBtnClick"
          >Decline
          </base-button>
        </div>
      </div>
      <div class="timer-block">
        <p class="countdown-text">Countdown:</p>
        <p class="timer-seconds"><span :class="{ red: countTime <= 10 }">{{ countTime }}</span>/30
          <svg v-if="countTime > 10" xml:space="preserve" viewBox="0 0 100 100" y="0" x="0"
               xmlns="http://www.w3.org/2000/svg" width="20px" height="20px"
               style="width:20px;height:20px;background-size:initial;background-repeat-y:initial;background-repeat-x:initial;background-position-y:initial;background-position-x:initial;background-origin:initial;background-image:initial;background-color:rgb(255, 255, 255);background-clip:initial;background-attachment:initial;animation-play-state:paused"><g class="ldl-scale" style="transform-origin:50% 50%;transform:rotate(0deg) scale(0.8, 0.8);animation-play-state:paused" ><g style="animation-play-state:paused" ><path fill="#333" d="M47.429 53.064c-3.016-2.207-5.906-4.565-8.75-6.977-2.852-2.402-5.629-4.894-8.392-7.404-2.738-2.538-5.46-5.096-8.107-7.743-2.655-2.637-5.264-5.329-7.745-8.174a2.024 2.024 0 0 1 2.571-3.064c3.232 1.949 6.336 4.052 9.395 6.208 3.066 2.147 6.058 4.384 9.033 6.64 2.951 2.285 5.887 4.587 8.748 6.979 2.869 2.382 5.693 4.818 8.39 7.406a4.01 4.01 0 0 1-5.143 6.129z" style="fill:rgb(70, 165, 41);animation-play-state:paused" ></path></g><path
              stroke-miterlimit="10" stroke-linejoin="round" stroke-linecap="round" stroke-width="6"
              stroke="#333"
              fill="none"
              d="M49.833 27.833V12.5c20.711 0 37.5 16.789 37.5 37.5s-16.789 37.5-37.5 37.5-37.5-16.789-37.5-37.5"
              style="stroke:rgb(70, 165, 41);animation-play-state:paused"></path></g></svg>
          <svg v-if="countTime <= 10" xml:space="preserve" viewBox="0 0 100 100" y="0" x="0"
               xmlns="http://www.w3.org/2000/svg" width="20px" height="20px"
               style="width:20px;height:20px;background-size:initial;background-repeat-y:initial;background-repeat-x:initial;background-position-y:initial;background-position-x:initial;background-origin:initial;background-image:initial;background-color:rgb(255, 255, 255);background-clip:initial;background-attachment:initial;animation-play-state:paused"><g class="ldl-scale" style="transform-origin:50% 50%;transform:rotate(0deg) scale(0.8, 0.8);animation-play-state:paused" ><g style="animation-play-state:paused" ><path fill="#333" d="M47.429 53.064c-3.016-2.207-5.906-4.565-8.75-6.977-2.852-2.402-5.629-4.894-8.392-7.404-2.738-2.538-5.46-5.096-8.107-7.743-2.655-2.637-5.264-5.329-7.745-8.174a2.024 2.024 0 0 1 2.571-3.064c3.232 1.949 6.336 4.052 9.395 6.208 3.066 2.147 6.058 4.384 9.033 6.64 2.951 2.285 5.887 4.587 8.748 6.979 2.869 2.382 5.693 4.818 8.39 7.406a4.01 4.01 0 0 1-5.143 6.129z" style="fill:rgb(225, 15, 15);animation-play-state:paused" ></path></g><path
              stroke-miterlimit="10" stroke-linejoin="round" stroke-linecap="round" stroke-width="6"
              stroke="#333"
              fill="none"
              d="M49.833 27.833V12.5c20.711 0 37.5 16.789 37.5 37.5s-16.789 37.5-37.5 37.5-37.5-16.789-37.5-37.5"
              style="stroke:rgb(225, 15, 15);animation-play-state:paused"></path></g></svg>
        </p>
      </div>
    </base-modal>
  </div>
</template>
<script>
import {defineComponent, ref, onUnmounted, watchEffect, inject, onBeforeMount} from "vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import {useWindowSize} from "vue-window-size";
import {useUserStore} from "@/store/user";
import {useWorkingSessionStore} from "@/store/workingSession";
import {useMeetStore} from "@/store/meet";
import  {getUserCallHistoryByDay} from "@/API/API-history";
import HistoryTable from "@/modules/hra/components/HistoryTable.vue";

export default defineComponent({
  components: {  PulseLoader,HistoryTable },
  setup() {
    // const router = useRouter();
    const userStore = useUserStore();
    const workingSessionStore = useWorkingSessionStore();
    const meetStore = useMeetStore();
    const { width } = useWindowSize();
    const chartMedia = 600; // @media for charts
    const callAlarm = ref(false); // red backgroud form call-modal window
    const todayCallHistory = ref([])
    const isStartModal = ref(false);
    const isStopModal = ref(false);
    const audio = new Audio(require("../../../assets/audio/ready.mp3"));
    let countTime = ref(30);
    let setCountTime = ref(null);
    const tableHeaders = ref([
      { text: "Date & Time", value: "start.time", sortable: true },
      { text: "Status", value: "status", sortable: true },
      { text: "Call Duration", value: "duration", sortable: true },
      { text: "Patient Name", value: "patient", sortable: true },
    ])
    onBeforeMount(()=> {
      getUserCallHistoryByDay(workingSessionStore.getScheduleId)
        .then(r=> {
          todayCallHistory.value = r.data.response
        })
    })

    const setTimer = () => {
      setCountTime.value = setInterval(() => {
        if (countTime.value <= 10) {
          callAlarm.value = true;
        }

        if (countTime.value === 0) {
          meetStore.setRinging(false);
          callAlarm.value = false;
          clearTimer();
          workingSessionPause();
        } else {
          --countTime.value;
        }
      }, 1000);
    };

    function clearTimer() {
      countTime.value = 30;
      clearInterval(setCountTime.value);
    }

    function resetTimer() {
      clearTimer();
      setTimer();
    }

    watchEffect(() => {
      if (meetStore.isRinging)
        callWindowShow()
      else
        callWindowHide()
    });



    onUnmounted(() => {
      audio.pause();
      audio.currentTime = 0;
    });

    function callWindowShow() {
      audio.loop = true;
      audio.play();

      resetTimer();
    }
    function callWindowHide() {
      audio.pause();
      audio.currentTime = 0;
    }

    const workingSessionStart = () => {
      userStore.setDisActiveLink(true);

      workingSessionStore.setActive(true);
      isStartModal.value = false;

      Socket.value.sessionOpen()
    };

    const workingSessionStop = () => {
      userStore.setDisActiveLink(false);
      workingSessionStore.setActive(false);
      meetStore.setRinging(false);
      workingSessionStore.setPaused(false);

      Socket.value.sessionClose()
    };

    const workingSessionPause = () => {
      userStore.setDisActiveLink(false);

      workingSessionStore.setActive(true);
      workingSessionStore.setPaused(true);

      Socket.value.sessionPause()
    };
    const workingSessionUnpause = () => {
      userStore.setDisActiveLink(true);

      workingSessionStore.setActive(true);
      workingSessionStore.setPaused(false);

      Socket.value.sessionUnPause()
    };

    const onAcceptPatientBtnClick = () => {
      clearTimer();
      callAccept();

      // router.push({ name: "HRAForm" });
    };

    const onDeclinePatientBtnClick = () => {
      clearTimer();
      meetStore.setRinging(false);
      Socket.value.callDecline()
      workingSessionPause();
    };

    // SOCKETS
    const Socket = inject('Socket')
    const isSocketReady = inject('isSocketReady')

    function callAccept() {
      meetStore.setRinging(false);
      Socket.value.callConfirm()
    }

    return {
      userStore,
      workingSessionStore,
      meetStore,
      isStartModal,
      isStopModal,
      workingSessionStart,
      workingSessionStop,
      workingSessionPause,
      workingSessionUnpause,
      onAcceptPatientBtnClick,
      onDeclinePatientBtnClick,
      isSocketReady,
      width,
      chartMedia,
      callAlarm,
      countTime,
      tableHeaders,
      todayCallHistory
    };
  },
});
</script>
<style scoped lang="scss">
.no-calls-text {
  font-size: 34px;
  text-align: center;
}
.history-table-container {
 padding-bottom: 140px;
}
.timer-seconds {
  padding: 10px 0;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer-seconds span {
  color: green;
  font-weight: 600;
}

.timer-seconds span.red {
  color: red !important;
}

.countdown-text {
  font-weight: 600;
  font-size: 22px;
}

.timer-block {
  padding: 20px 0 0;
  text-align: center;
}

.grid-loader {
  margin: 0 auto;
}

.button-group {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px;
}

.span-two {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 20px;
}

.text-chart {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.text-sure {
  padding: 0 0 20px;
}

.chart-column {
  display: grid;
  grid-template-columns: repeat(3, auto);
}

.waiting-text {
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 124px;
  text-align: center;
  color: #a1a1a1;
}

.patient-name-text {
  display: inline-block;
  margin: 5px 0;
  font-size: 34px;
  font-weight: 600;
}

.b {
  border: 2px solid var(--color-gray);
  border-radius: 19px;
}

.br {
  width: 2px;
  background-color: var(--color-gray);
}

@media (max-width: 1350px) {
  .chart-column {
    display: grid;
  }
}

@media (max-width: 992px) {
  .waiting-text {
    font-size: 30px;
  }
}

@media (max-width: 1200px) {
  .grid-charts {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .grid-charts {
    display: grid !important;
    justify-content: center;
    gap: 30px;
  }

  .waiting-text {
    font-size: 22px;
  }
}

@media (max-width: 470px) {
  .text-chart {
    font-size: 12px;
  }
}

//newstyles

.dashboard-wrap {
  padding-top: 46px;
}

.user-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-size: 60px;
  margin-bottom: 40px;

  @media (max-width: 1250px) {
    font-size: 45px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
  }
}

.user-name-wrap {
  @media (max-width: 1250px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.chart-wrap {
  display: flex;
  justify-content: space-between;
}

.loader-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.call-modal-btn-wrap {
  display: flex;
  gap: 12px;
  justify-content: center;
  padding-top: 12px;

  button {
    padding: 20px 50px;
  }

  @media (max-width: 600px) {
    button {
      width: max-content;
      padding: 20px 20px;
    }
  }
}
</style>