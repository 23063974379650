<template>
  <vue-tel-input />
</template>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  setup() {
    return {}
  }
})
</script>
<style lang="scss">
.vue-tel-input-tag.error {
  box-shadow: inset 0 0 0 0 red, 0 0 3px red;
}

.vue-tel-input {
  border: none;
  position: relative;
  display: block;
  height: 3.5rem !important;
  background-color: var(--color-light-gray);
  border-radius: 0.75rem;
  color: var(--color-gray-text);
  padding: 0.9375rem;
}

.vti__dropdown-list {
  width: 100%;
  position: absolute;
  background-color: var(--color-light-gray);

  color: var(--color-gray-text);
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 26px !important;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.vti__input {
  position: absolute !important;
  color: var(--color-gray-text) !important;
  background-color: var(--color-light-gray) !important;
  width: 50% !important;
  top: 35% !important;
  left: 30% !important;
}

#placeholder {
  display: none !important;
}

</style>