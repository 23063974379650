import { defineStore } from "pinia";

export const useMeetStore = defineStore("meet", {
  state: () => ({
    isRinging: false,
    isMeetInited: false,
    roomName: "",
    nickname: "",
    avatar: "",
    patient: {
      name: "",
      sex: "",
      yo: null,
      user_id: "",
      patient_id: ""
    },
  }),
  persist: {
    storage: localStorage,
    paths: ['patient'],
  },
  getters: {
    // get computed properties from state
    getRoomName: (state) => state.roomName,
    getIsMeetInited: (state) => state.isMeetInited,
    getPatientId: (state) => state.patient.patient_id,
    getPatientUserId: (state) => state.patient.user_id
  },
  // methods from change state
  actions: {
    setPatientData(data) {
      this.patient = { ...this.patient, ...data };
    },
    setRinging(value) {
      this.isRinging = value;
    },
    setRoomSettings(name) {
      this.roomName = name;
      this.isMeetInited = true;
    },
    setRoomClear() {
      this.roomName = '';
      this.isMeetInited = false;
    }
  },
});
