import AlcoholScreening from "@/assets/images/svg/services/alcohol-screening.svg";
import BloodScreening from "@/assets/images/svg/services/blood-screening.svg";
import ColorectalCancer from "@/assets/images/svg/services/colorectal-cancer.svg";
import Covid from "@/assets/images/svg/services/covid.svg";
import GeneticHealth from "@/assets/images/svg/services/genetic-health.svg";
import HealthRisk from "@/assets/images/svg/services/health-risk.svg";
import Nutrition from "@/assets/images/svg/services/nutrition.svg";
import PhysicalFitness from "@/assets/images/svg/services/physical-fitness.svg";

export default [
  {
    title: "Health risk assessments",
    img: HealthRisk,
  },
  {
    title: "COVID & Flu testing",
    img: Covid,
  },
  {
    title: "Doctor consult in Genetic health screening",
    img: GeneticHealth,
  },
  {
    title: "Alcohol misuse screening and counseling",
    img: AlcoholScreening,
  },
  {
    title: "Nutrition assessment",
    img: Nutrition,
  },
  {
    title: "Physical fitness assessment",
    img: PhysicalFitness,
  },
  {
    title: "Blood screening",
    img: BloodScreening,
  },
  {
    title: "Colorectal cancer screening",
    img: ColorectalCancer,
  },
];
