<template>
    <div>
        <slot></slot>
        <p class="xs">©2022. Laboworq LLC company</p>   
    </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
    })
</script>
