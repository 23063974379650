<template>
  <p>
    <slot></slot>
  </p>
</template>
<script>
import {defineComponent} from "vue";

export default defineComponent({})
</script>
<style lang="scss">
// color
p.color-primary {
  color: var(--color-primary)
}

p.color-success {
  color: var(--color-success)
}

p.color-danger {
  color: var(--color-danger)
}

p.color-white {
  color: var(--color-white)
}

p.color-gray {
  color: var(--color-gray-text)
}

//size
p.fs-8 {
  font-size: 8px;
}

p.fs-12 {
  font-size: 12px;
}

p.fs-14 {
  font-size: 14px;
}

p.fs-16 {
  font-size: 16px;
}

p.fs-20 {
  font-size: 20px;
}

p.fs-34 {
  font-size: 34px;
}

p.large {
  font-size: 88px;
}

p.medium {
  font-size: 48px;
}

p.small {
  font-size: 22px;
}

//weight
p.thin {
  font-weight: 300;
}

p.bold {
  font-weight: 500;
}

p.bolder {
  font-weight: 600;
}

//text transform
p.uppercase {
  text-transform: uppercase;
}

p.lowercase {
  text-transform: lowercase;
}

p.capitalize {
  text-transform: capitalize;
}

//text decoration
p.underline {
  text-decoration: underline inherit;
}

p.underline-dotted {
  text-decoration: underline dotted inherit;
}
</style>