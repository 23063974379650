import loadScript from "@/plugins/jitsi_meet/utils/load-script";
import optionsCreator from "../services/options-creator.js";

const createRoom = async (onload) => {
  const API_URL = "https://meet.healeze.com/external_api.js";
  const API_HEALEZE_MEET_URL = "meet.healeze.com";
  const API_CREATOR = await loadScript(API_URL, "JitsiMeetExternalAPI");
  const meet_options = optionsCreator(onload);
  const meet_api = new API_CREATOR(API_HEALEZE_MEET_URL, meet_options);
  console.log("meet_api", meet_api);
};

export default createRoom;
