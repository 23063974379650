<template>
  <apexchart
    :width="widthVal"
    :height="'80%'"
    :options="options"
    :series="[Number(props.value) < 10 ? '0' + props.value : props.value]"
  />
</template>

<script>
import { defineComponent, ref, watchEffect } from "vue";
export default defineComponent({
  props: {
    label: String,
    value: Number,
    color: String,
    width: Number,
  },
  setup(props) {
    const widthVal = ref(props.width);

    watchEffect(() => {
      widthVal.value = props.width;
    });

    const options = ref({
      chart: {
        type: "radialBar",
        // offsetX: -35,
      },
      colors: [props.color],
      plotOptions: {
        radialBar: {
          hollow: {
            // margin: 20,
            size: "70%",
            background: "#fff",
          },
          track: {
            strokeWidth: "100%",
            margin: 2,
          },
          dataLabels: {
            name: {
              offsetY: 58,
              color: "#000000",
              fontSize: "10px",
              fontWeight: 500,
            },
            value: {
              offsetY: -11,
              color: props.color,
              fontSize: "12px",
              fontWeight: 600,
              show: true,
              formatter: function (val) {
                return val;
              },
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            plotOptions: {
              radialBar: {
                hollow: {
                  margin: 10,
                  size: "15%",
                  background: "#fff",
                },
                track: {
                  strokeWidth: "100%",
                  margin: 12,
                },
                dataLabels: {
                  name: {
                    offsetY: 38,
                    color: "#000000",
                    fontSize: "10px",
                    fontWeight: 500,
                  },
                  value: {
                    offsetY: -10,
                    color: props.color,
                    fontSize: "12px",
                    fontWeight: 600,
                    show: true,
                    formatter: function (val) {
                      return val;
                    },
                  },
                },
              },
            },
          },
        },
      ],
      labels: [props.label ? props.label : ""],
    });
    return { options, widthVal, props };
  },
});
</script>
<style lang="scss">
</style>