<template>
  <div class="title-wrap">
    <img class="title-avatar" src="../assets/img/my-info-img.png" alt="IMG" />
    <p class="title mb-20">Welcome, {{ userStore.name }}!</p>
  </div>
  <p class="title-information">Personal Information</p>
  <ul class="personal-info-container">
    <li v-if="userStore.phone.length" class="personal-info-item">
      <div class="svg-container">
        <img :src="telSvg" alt="tel" />
      </div>
      {{ userStore.phone }}
    </li>
    <li v-if="userStore.getDOB" class="personal-info-item">
      <div class="svg-container">
        <img :src="dobSvg" alt="dob" />
      </div>
      {{ userStore.getDOB }}
    </li>
    <li v-if="userStore.email.length" class="personal-info-item">
      <div class="svg-container">
        <img :src="emailSvg" alt="email" />
      </div>
      {{ userStore.email }}
    </li>
    <li v-if="userStore.sex.length" class="personal-info-item">
      <div class="svg-container">
        <img :src="genderSvg" />
      </div>
      {{ userStore.sex }}
    </li>
  </ul>
</template>
<script>
import {defineComponent, onBeforeMount, ref} from "vue";
import telSvg from "../assets/img/tel.svg";
import dobSvg from "../assets/img/b-day.svg";
import emailSvg from "../assets/img/email.svg";
import genderSvg from "../assets/img/gender.svg";
import {useUserStore} from "@/store/user";
import {getUserData} from "@/API/API-user";

export default defineComponent({
  setup() {
    const userStore = ref(useUserStore());

    /**
     * get User Data before component will mount
     */
    onBeforeMount(() => {
      getUserData().then((r) => {
        console.log(r);
        // userStore.value.setUserData(r.data.user);
      });
    });

    return { userStore, telSvg, dobSvg, emailSvg, genderSvg };
  },
});
</script>
<style scoped lang="scss">
.personal-info-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 500px;
  margin-bottom: 60px;

  @media (max-width: 650px) {
    width: 100%;
  }

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
}

.svg-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
}

.personal-info-item {
  display: flex;
  align-items: center;
  gap: 20px;
}

.title {
  font-size: 60px;

  & span {
    font-weight: 600;
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  @media (max-width: 650px) {
    font-size: 32px;
    margin-bottom: 0px;
    text-align: center;
  }
}

.title-avatar {
  border-radius: 100%;
  width: 250px;
  height: 250px;

  @media (min-width: 1000px) {
    display: none;
  }

  @media (max-width: 650px) {
    width: 150px;
    height: 150px;
  }
}

.title-wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  gap: 60px;
  margin-bottom: 24px;

  @media (max-width: 650px) {
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }
}

.title-information {
  display: inline-block;
  font-size: 22px;
  margin-bottom: 26px;

  @media (max-width: 650px) {
    width: 100%;
    text-align: center;
  }
}
</style>