<template>
  <header class="header container">
    <div class="logo-container">
      <router-link :to="{ name: 'WaitingPage' }">
        <img :src="LogoSvg" alt="Logo" />
      </router-link>
    </div>
    <nav-menu v-if="userStore.token" />
    <router-link :class="{ disActive: userStore.getDisActiveLink }" v-if="userStore.token" to="/account">
      <div class="img-container">
        <img v-if="!user_img" :src="user_img" alt="user-avatar" />
        <img v-if="user_img" :src="user_img" alt="user-avatar" />
      </div>
      <p class="account-text">My account</p>
    </router-link>
  </header>
</template>

<script>
import { defineComponent, ref } from "vue";
import NavMenu from "@/components/NavMenu.vue";
import LogoSvg from "../assets/images/svg/logo.svg";
import MyAccountCrossSvg from "@/assets/images/svg/my-acc-cross.svg";
import avatarTest from "@/modules/user/assets/img/my-info-img.png";
import { useUserStore } from "@/store/user";
export default defineComponent({
  components: { NavMenu },

  setup() {
    const userStore = useUserStore();

    // TODO temporary data
    let user_img = ref(avatarTest);
    return {
      LogoSvg,
      MyAccountCrossSvg,
      user_img,
      userStore,
    };
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 34px;
  border-bottom: 1px solid #e1e1e1;

  @media (max-width: 1366px) {
    padding: 16px 32px;
  }

  @media (max-width: 600px) {
    padding: 16px 16px;
  }
}

.disActive{
  pointer-events: none;
}

.logo-container {
  width: 115px;
  height: 105px;

  @media (max-width: 650px) {
    width: 85px;
    height: 75px;
  }
}

.img-container {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border: 1px solid var(--color-gray);
  border-radius: 50%;
  cursor: pointer;
}

.account-text {
  color: var(--color-gray-text);
  font-size: 13px;
  text-align: center;
  width: 100%;
  margin-top: 4px;
  font-weight: 500;
}
</style>
