<template>
  <the-header />
  <main>
    <router-view></router-view>
  </main>
  <the-footer v-if="route.path !== '/welcome'" class="ta-c pt-25 pb-25" />
</template>

<script>
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    return { route };
  },
});
</script>
<style lang="scss">
.ta-c {
  text-align: center;
}
</style>