<template>
  <div class="container">
    <div class="login-container col-sm-12 col-xl-9">
      <div class="login-form-wrapper">
        <base-text class="medium d-i-block mb-30">Login for Doctors</base-text>
        <base-tel-input
          aria-autocomplete="false"
          v-model="state.phone"
          @input="onVueTelInput"
          :value="state.phone"
          class="vue-tel-input-tag"
          :class="v$.phone.$error ? 'error' : ''"
        />
        <div v-if="v$.phone.$error" class="red-text mt-5">
          {{ v$.phone.$errors[0].$message }}
        </div>
        <base-list/>

        <base-input
          type="text"
          v-if="isCodeSent"
          v-model="state.code"
          placeholder="Enter Code"
          class="input-code input mt-30"
          :class="v$.code.$error ? 'error' : ''"
        />
        <template v-if="isCodeSent && v$.code.$error">
          <p class="red-text mt-5">Enter correct code</p>
        </template>
        <label class="container-cbx">
          <input class="checkbox-input" type="checkbox" v-model="state.agree" />
          <span
            class="checkmark"
            :class="v$['agree'].$error ? 'danger' : ''"
          ></span>
          <span class="policy-text">
            I agree to receive SMS/text massages. <br class="mobile" />
            SMS data rates may apply. I agree with
            <a href="#" class="policy-URL">Privacy Policy</a>
          </span>
          <span v-if="v$.agree.$error" class="red-text agreement">{{
              v$.agree.$errors[0].$message
            }}</span>
        </label>
        <BaseButton class="btn primary" @click="submitForm">
          {{ isCodeSent ? "Log In" : "Get SMS" }}
        </BaseButton>
      </div>
      <img
        class="login-img-container"
        src="@/assets/images/login-form.jpg"
        alt="as"
      />
      <notifications position="top center" width="300px" />
    </div>
  </div>
  <BaseLoader v-if="loader_active" class="loader-on-block"></BaseLoader>
</template>
<script>
import {defineComponent, ref} from "vue";
import useValidate from "@vuelidate/core";
import {useRouter} from "vue-router";
import loginFormRules from "@/modules/user/services/login-form-rules";
import {notify} from "@kyvg/vue3-notification";
import {checkCode, getCode} from "@/API/API-user";
import {useUserStore} from "@/store/user";
import BaseInput from "@/components/base/BaseInput";
import BaseList from "@/components/base/BaseList";

export default defineComponent({
  components: { BaseList, BaseInput },
  setup() {
    const userStore = useUserStore();

    const loader_active = ref(false);
    const router = useRouter();
    const state = ref({
      phone: "",
      email: "",
      agree: false,
      code: "",
    });
    let isValid = ref(false);
    const isCodeSent = ref(false);
    let data = {};
    const v$ = useValidate(loginFormRules, state);

    const onVueTelInput = (_, phoneObject) => {
      if (phoneObject?.number) state.value.phone = phoneObject.number;
      isCodeSent.value = false
    };

    /**
     * Get and Check code for user login
     */
    const submitForm = () => {
      v$.value.$validate().then(() => {
        if (!isCodeSent.value) {
          if (!v$.value.$validationGroups["phone"].$error) {
            loader_active.value = true;

            getCode({ phone: state.value.phone }).then((res) => {
              if (res.data.status === "success") {
                isCodeSent.value = true;
                notify({
                  title: res.data.title,
                });

                loader_active.value = false;
              } else {
                notify({
                  title: `Destination number ${state.value.phone} is invalid`,
                });
                loader_active.value = false;
              }
            });
          }
        } else {
          if (!v$.value.$validationGroups["code"].$error) {
            checkCode({
              phone: state.value.phone,
              code: state.value.code,
            }).then((res) => {
              if (res.data.status === "success") {
                if (res.data.user.type_id !== "6") {
                  notify({
                    title: `Your account is not allowed. Please contact with support`,
                  });
                } else {
                  localStorage.setItem("token", res.data.user.token);
                  userStore.setUserData(res.data.user);
                  router.push({ name: "WaitingPage" });
                }
              }
            });
          }
        }
      });
    };
    return {
      state,
      v$,
      submitForm,
      onVueTelInput,
      isCodeSent,
      isValid,
      data,
      loader_active,
    };
  },
});
</script>
<style scoped lang="scss">
.mobile {
  display: none;

  @media (max-width: 850px) {
    display: unset;
  }
}

.main-text {
  @media (max-width: 1100px) {
    font-size: 30px;
  }

  @media (max-width: 970px) {
    font-size: 26px;
  }
}

.input-code {
  width: 100%;
}

.red-text {
  color: #de3232;
}

.red-text.agreement {
  padding-top: 12px;
  font-size: 12px;
}

.checkmark.danger {
  border: 1px solid #ff000087;
}


.login-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 auto;

  @media (max-width: 650px) {
    justify-content: center;
  }
}

.login-img-container {
  width: 400px;
  border: 1px solid var(--color-gray);
  border-radius: 50%;

  @media (max-width: 850px) {
    display: none;
  }
}

.login-type-btn {
  cursor: pointer;
  font-weight: 400;
  font-size: 18px;
  line-height: 12px;
  padding: 0 68px 10px;
  margin-bottom: 40px;
  color: var(--color-light-gray);
  border-bottom: 1px solid var(--color-light-gray);

  &.active {
    color: var(--color-black);
    border-bottom: 1px solid var(--color-green);
  }
}

.login-form-wrapper {
  @media (max-width: 500px) {
    button {
      width: 100%;
    }
  }
}

.policy-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
}

.policy-URL {
  color: #29abe2;
  cursor: pointer;
}

.container-cbx {
  display: grid;
  line-height: 0.1;
  text-align: left;
  position: relative;
  padding-left: 35px;
  margin-bottom: 35px;
  cursor: pointer;
  font-size: 22px;
  margin-top: 45px;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}

.container-cbx input:checked ~ .checkmark {
  background-color: #f5f5f5;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container-cbx input:checked ~ .checkmark:after {
  display: block;
}

.container-cbx .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: 1px solid #29abe2;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.policy-text {
  max-width: 400px;
}
</style>
