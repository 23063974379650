<template>
  <select2 />
</template>
<script>
import {defineComponent} from "vue";
import Select2 from "vue3-select2-component";

export default defineComponent({
  components: { Select2 },
  setup() {
    return {}
  }
})
</script>
<style lang="scss">
.select2-container {
  width: 100% !important;
}

.select2-search--dropdown {
  display: none !important;
}


.select2-selection__rendered {
  height: 3.5rem;
  background-color: var(--color-light-gray) !important;
  border-radius: 0.75rem !important;
  color: var(--color-gray-text) !important;
  padding: 0.9375rem;
  padding-left: 14px !important;

}

.select2-dropdown--below {
  margin-top: 5px !important;
  border: none !important;
}

.select2-search__field {
  display: none !important;
}

.select2-results__options {
  color: var(--color-gray-text);
  border: 1px solid var(--color-gray-text) !important;
  border-radius: 0.75rem !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 26px !important;

  &::-webkit-scrollbar {
    display: none !important;
  }

}

.select2-results__option--highlighted {
  background-color: var(--color-blue) !important;
}

.select2-selection--single {
  border: none !important;
  height: 3.5rem !important;
  border-radius: 0.75rem !important;
}

.select2.error .select2-selection__rendered {
  border-radius: 0.75rem !important;
  border: 1px solid var(--color-red);
  animation: danger-border 2.5s forwards;
}

.select2-selection__arrow {
  top: 0.938rem !important;
}

.select2-container--open .select2-dropdown--above {
  border: none !important;
  border-radius: 12px;
}
</style>