<template>
  <div class="container">
    <div class="row pt-60">
      <div class="col-md-6 col-12">
        <VideoBoxWithPatient>
          <base-button
              @click="callClose()"
              class="end-call-btn btn danger col-md-12">
            Disconnect
          </base-button>
        </VideoBoxWithPatient>
      </div>
      <div class="col-md-6 col-12">
        <template v-if="!isSocketReady || !form_config_JSON">
          <div class="loader-wrap">
            <div class="d-flex justify-content-center">
              <p class="waiting-text mr-5">Connecting</p>
              <pulse-loader :loading="true" :color="'#47b6e4'" class="pulse-loader"></pulse-loader>
            </div>
          </div>
        </template>
        <template v-else>
          <HraForm
              @update-state="(val) => hraSocketUpdate(val)"
              :config="form_config_JSON"
              :state_updated="hraStore.state"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, inject, onMounted, ref} from "vue";
import HraForm from "@/modules/hra/components/HraForm.vue";
import VideoBoxWithPatient from "@/modules/hra/components/VideoBoxWithPatient.vue";
import {API_hra_init} from "@/API/API-hra";
import {useHraStore} from "@/modules/hra/store/hra";
import PulseLoader from "vue-spinner/src/PulseLoader";
import BaseButton from "@/components/base/BaseButton";

export default defineComponent({
  components: {BaseButton, VideoBoxWithPatient, HraForm, PulseLoader},
  setup() {
    const hraStore = useHraStore();

    const state = ref({
      // 'first-name': '',
      // 'last-name': '',
      //  ...
      //  ...
    });

    const form_config_JSON = ref(null);

    /**
     * get current patient
     */
    onMounted(() => {
      API_hra_init().then((res) => {
        form_config_JSON.value = res.data.response;

        Socket.value.callUpdateRequest()
      });
    });

    // SOCKETS
    const Socket = inject('Socket')
    const isSocketReady = inject('isSocketReady')

    function hraSocketUpdate(state_val) {
      console.log(Socket.value);
      console.log('test', state_val);
      Socket.value.callUpdate(state_val)
    }

    function callClose() {
      Socket.value.callClose()
    }

    return {
      state,
      isSocketReady,
      hraStore,
      hraSocketUpdate,
      callClose,
      form_config_JSON
    };
  },
});
</script>

<style lang="scss">

.call_modal {
  margin-top: 30px;

  @media (max-width: 767px) {
    margin-top: 15px;
  }
}


.end-call-btn {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 40px;
  padding-right: 40px;

  @media (max-width: 767px) {
    width: 100%;
  }
}

</style>