<template>
  <ul class="d-flex jc-c">
    <li :class="{ active: path === '/work' }" class="link-text cp mr-30" @click="router.push({ name: 'WaitingPage' })">
      Dashboard
    </li>
    <li :class="{
      active: path === '/history',
      disActive: userStore.getDisActiveLink
    }"
    :title="userStore.getDisActiveLink ? 'Link will work after Punch Out or in pause.' : ''"
    class="link-text cp mr-30"
    @click="routerPush('History', userStore.getDisActiveLink)"
    
    >
      History
    </li>
    <li :class="{
      active: path === '/account', disActive: userStore.getDisActiveLink
    }"
    class="link-text cp" @click="routerPush('AccountPage', userStore.getDisActiveLink)"
    :title="userStore.getDisActiveLink ? 'Link will work after Punch Out or in pause.' : ''"
    >
      My Account
    </li>
  </ul>
</template>
<script>
import { defineComponent, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useUserStore } from "@/store/user";

export default defineComponent({
  setup() {
    const userStore = useUserStore();
    const router = useRouter();
    const route = useRoute();
    const path = computed(() => route.path);

    function routerPush(link, status) {
      if (!status) {
        router.push({ name: link })
      }
    }


    return { router, path, userStore, routerPush };
  },
});
</script>
<style scoped lang="scss">
.link-text {
  font-size: 20px;
  font-weight: 500;
}

.active {
  color: var(--color-primary);
}

.disActive {
  color: rgb(226, 222, 222);
}
</style>