<template>
  <div class="d-flex wrapper">
    <div class="img-container col-md-6 mr-30">
      <img :src="AboutUsIMG" alt="AboutUsIMG">
    </div>
    <div class="content-container col-md-6 ml-20">
      <div>
        <div class="p-20">
          <h2 class="about-us-title b-b pb-10 mb-30">WHO WE ARE</h2>
          <p class="about-us-text">
            We are a mobile health company that takes the complications out of
            preventative healthcare and provides you access to the doctors that
            can serve you best.
          </p>
        </div>
        <div class="p-20">
          <h2 class="about-us-title d-block b-b pb-10 mb-30">OUR SCOPE</h2>
          <p class="about-us-text">
            We move administrative headaches away from doctors and patients and
            put it on ourselves, so that you can focus on staying healthy and
            living life at its fullest.
          </p>
        </div>
        <div class="wrapper-button">
          <p>Download the Healeze app and find out how easy healthcare can be.</p>
          <div class="span-two">
            <base-button-app-store class="btn appstore" :text1="'Download on the'" :text2="'App Store'" :image="0" />
            <base-button-app-store class="btn appstore" :text1="'Get it on'" :text2="'Google Play'" :image="1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent} from "vue";
import AboutUsIMG from '../../../assets/images/about-us.png'
import BaseButtonAppStore from "@/components/base/BaseButtonAppStore";

export default defineComponent({
  components: { BaseButtonAppStore },
  setup() {
    return { AboutUsIMG };
  },
});
</script>
<style scoped lang="scss">
.wrapper {
  padding: 80px 60px;
  background-color: #f5f5f5;
}

.wrapper-button {
  border: 1px solid #D9D9D9;
  border-radius: 12px;
  padding: 15px;
}

.wrapper-button p {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  color: #000000;
}

.span-two {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px;
}

.about-us-title {
  font-size: 22px;
  font-weight: 700;
}

.about-us-text {
  font-size: 21px;
}

.b-b {
  border-bottom: 1px solid #c5c5c5;
}

@media (max-width: 576px) {
  .img-container {
    display: none;
  }
}

@media (max-width: 545px) {
  .span-two {
    padding: 20px 0 0;
    grid-template-columns: auto;
  }
}
</style>