<template>
  <div v-if="messageText" class="window" :class="{
    'black-pulse': !isSocketReady,
    'yellow-pulse': !workingSessionStore.isActive || workingSessionStore.isPaused,
    'green-pulse': workingSessionStore.isActive && !workingSessionStore.isPaused,
  }"> {{ messageText }}
    <div class="btn-container" v-if="isSocketReady">
      <base-button @click="workingSessionPause" v-if="workingSessionStore.isActive && !workingSessionStore.isPaused" class="btn mr-10
      primary">
        Pause
      </base-button>
      <base-button @click="workingSessionUnpause" v-if="workingSessionStore.isActive && workingSessionStore.isPaused" class="btn mr-10
      primary">
        Unpause
      </base-button>
      <base-button v-if="!workingSessionStore.isActive" @click="workingSessionStart" class="btn success">Punch In</base-button>
      <base-button v-else @click="workingSessionStop" class="btn danger">Punch Out</base-button>
    </div>
  </div>
</template>
<script>
import {defineComponent, computed, inject} from "vue";
import {useWorkingSessionStore} from "@/store/workingSession";
import BaseButton from "@/components/base/BaseButton";
import {useMeetStore} from "@/store/meet";
import {useUserStore} from "@/store/user";


export default defineComponent({
  components: { BaseButton },
  setup() {
    const isSocketReady = inject('isSocketReady')
    const workingSessionStore = useWorkingSessionStore()
    const meetStore = useMeetStore();
    const userStore = useUserStore();


    let messageText = computed(() => {
      if (!isSocketReady.value) {
        return 'Connecting...'
      }
      if (workingSessionStore.isActive && !workingSessionStore.isPaused) {
        return 'Waiting for call...'
      }
      if (workingSessionStore.isActive && workingSessionStore.isPaused) {
        return 'Paused'
      }
      if (!workingSessionStore.isActive) {
        return 'Click "Punch In" to start session'
      }
      return messageText
    })
    const Socket = inject('Socket')
    /**
     * Stop working session
     */
    const workingSessionStart = () => {
      userStore.setDisActiveLink(true);
      workingSessionStore.setActive(true);
      workingSessionStore.setPaused(false);

      Socket.value.sessionOpen()
    };

    /**
     * Stop working session
     */
    const workingSessionStop = () => {
      userStore.setDisActiveLink(false);
      
      workingSessionStore.setActive(false);
      meetStore.setRinging(false);
      workingSessionStore.setPaused(false);

      Socket.value.sessionClose()
    };

    /**
     * Set working session on pause
     */
    const workingSessionPause = () => {
      userStore.setDisActiveLink(false);
      workingSessionStore.setActive(true);
      workingSessionStore.setPaused(true);
      Socket.value.sessionPause()
    };

    /**
     * Unpause working session
     */
    const workingSessionUnpause = () => {
      userStore.setDisActiveLink(true);


      workingSessionStore.setActive(true);
      workingSessionStore.setPaused(false);
      Socket.value.sessionUnPause()
    };
    return { messageText, isSocketReady, workingSessionStore, workingSessionStart, workingSessionStop, workingSessionPause, workingSessionUnpause }
  }
})
</script>
<style scoped lang="scss">
.window {
  position: fixed;
  z-index: 99;
  bottom: 30px;
  right: 30px;
  background: #FFFFFF;
  padding: 30px 40px;
  border: 1px solid;
  border-radius: 12px;
  text-align: center;
}

.btn-container .btn {
  margin-top: 25px;
}

.yellow-pulse {
  background: #fff5ca;
  box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
  border: 1px solid rgb(255, 177, 66);
  color: var(--color-black);
  animation: pulse-yellow 1.6s infinite;
}

.black-pulse {
  background: #ddedfa;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  border: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  animation: pulse-black 1.6s infinite;
}

.green-pulse {
  background: #a6fcd5;
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  border: 1px solid var(--color-white);
  font-size: 20px;
  color: var(--color-black);
  animation: pulse-green 1.6s infinite;
}

@keyframes pulse-black {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}


@keyframes pulse-yellow {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
  }

  70% {
    box-shadow: 0 0 0 30px rgba(255, 177, 66, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
  }
}


@keyframes pulse-green {
  0% {
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    box-shadow: 0 0 0 30px rgba(51, 217, 178, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
</style>