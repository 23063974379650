import axios from "axios";
import qs from "qs";
import { useUserStore } from "@/store/user";

const url = process.env.VUE_APP_API_URL;

/**
 * Header for non-authorize user
 * @type {{"content-type": string}}
 */
const headers = {
    "content-type": "application/x-www-form-urlencoded;charset=utf-8",
};

/**
 * Header for authorize user
 * @type {{"content-type": string}}
 */
// const headersWithToken = {
//     Authorization: "Bearer " + localStorage.getItem("token"),
//     "content-type": "application/x-www-form-urlencoded;charset=utf-8",
// };

/**
 *  Get data about current user
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getUserData() {
    const userStore = useUserStore()

    const headersAuthorized = {
        Authorization: "Bearer " + userStore.token,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    }

    return axios
        .post(
            url + "users",
            {},
            {
                headers: headersAuthorized,
            }
        )
        .then((r) => r);
}

/**
 *  Get code for login
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getCode(data) {
    return axios
        .post(url + "users/get_code", qs.stringify(data), {
            headers,
        })
        .then((r) => r);
}

/**
 *  Check code for login
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function checkCode(data) {
    return axios
        .post(url + "users/check_code", qs.stringify(data), {
            headers,
        })
        .then((r) => r);
}

/**
 *  Logout user from web-site
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function userLogout() {
    const userStore = useUserStore()

    const headersAuthorized = {
        Authorization: "Bearer " + userStore.token,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    }

    return axios
        .post(
            url + "users/logout",
            {},
            {
                headers: headersAuthorized,
            }
        )
        .then(r => r);
}
