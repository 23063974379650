<template>
  <div class="container">
    <welcome />
    <about-us />
    <our-services />
    <get-app />
    <our-partners />
    <the-footer class="d-flex jc-sb ai-c pb-20 pt-20">
      <welcome-footer />
    </the-footer>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Welcome from "@/modules/welcome/components/Welcome.vue";
import AboutUs from "@/modules/welcome/components/AboutUs.vue";
import OurServices from "@/modules/welcome/components/OurServices.vue";
import GetApp from "@/modules/welcome/components/GetApp.vue";
import OurPartners from "@/modules/welcome/components/OurPartners.vue";
import WelcomeFooter from "@/modules/welcome/components/WelcomeFooter.vue";
export default defineComponent({
  components: {
    Welcome,
    AboutUs,
    OurServices,
    GetApp,
    OurPartners,
    WelcomeFooter,
  },
});
</script>