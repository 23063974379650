<template>
  <ul v-for="item in props.data" :key="item">
   <li>
    <span v-if="item.header">{{ item.header }}</span>
   <span v-if="item.image">{{ item.image }}</span>
   <span v-if="item.text">{{ item.text }}</span>
   </li>
  </ul>
</template>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    data: Array
  },
  setup(props) {
    console.log(props)
    return { props }
  }
})
</script>
<style scoped lang="scss">
</style>