<template >
  <div class="col-md-12 center ta-c wrapper">
    <h2 class="our-services-title text-app">Our Services</h2>
    <ul class="our-services-list d-flex ai-c jc-c f-wrap">
      <li
        v-for="(service, index) in ourServicesData"
        :item="service"
        :index="index"
        :key="index"
        class="our-services-item"
      >
        <div class="d-flex jc-sb ai-c">
          <span class="d-i-block gray">
            {{ "0" + (index + 1) }}
          </span>
          <img :src="service.img" alt="service.title" />
        </div>
        <span class="service-title">
          {{ service.title }}
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ourServicesData from "../services/our-services";
export default defineComponent({
  setup() {
    return { ourServicesData };
  },
});
</script>
<style scoped lang="scss">
.wrapper {
  padding: 80px 0;
  background-image: url("~@/assets/images/login-form.jpg");
  background-size: 500px;
  //background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.our-services-item {
  background: #ffffff;
  display: grid;
  align-content: space-between;
  align-items: end;
  border: 3px solid var(--color-gray);
  height: 210px;
  width: 250px;
  margin: 10px;
  border-radius: 12px;
  padding: 15px;
}

.text-app {
  padding: 10px 0 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 75px;
  line-height: 90px;
  text-align: center;
  color: #000000;
}

.service-title {
  font-size: 24px;
}

@media (max-width: 545px) {
  .text-app {
    font-size: 40px;
    line-height: 50px;
  }
}
</style>