<template>
  <div class="ta-c patient-data">
    <p class="patient-data-subtext">Call from</p>
    <p class="patient-data-text">{{ meetStore.patient.name }}</p>
    <p class="patient-data-subtext">
      {{ meetStore.patient.sex }}, {{ meetStore.patient.yo }}
    </p>
  </div>
  <div class="video-call-container">
    <div :class="{ 'active': isCallModalVisible }" id="call_modal">
      <div @click="isCallModalVisible = !isCallModalVisible"
           v-touch:swipe.right="CallModalHide" class="hide-control"></div>
      <div>
        <VJitsi
            class="call-box"
            :nickname="meetStore.patient.name"
            :room="meetStore.roomName"
            :init="meetStore.isMeetInited"
        />
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
import {defineComponent, ref} from "vue";
import VJitsi from "@/plugins/jitsi_meet/components/VJitsi.vue";
import {useMeetStore} from "@/store/meet";
import {useUserStore} from "@/store/user";

export default defineComponent({
  components: {VJitsi},
  setup() {
    const meetStore = useMeetStore();
    const userStore = useUserStore();
    const isCallModalVisible = ref(true);

    function CallModalHide() {
      isCallModalVisible.value = false
    }

    return {meetStore, userStore, isCallModalVisible, CallModalHide};
  },
});
</script>
<style scoped lang="scss">
.video-call-container {
  position: sticky;
  top: 30px;
  @media (max-width: 767px) {
    position: unset;
  }
}

.patient-data {
  margin-bottom: 30px;
  @media (max-width: 767px) {
    display: none;
  }
}

.patient-data-text {
  font-size: 34px;
}

.patient-data-subtext {
  font-size: 20px;
}

.call-box {
  height: 500px;
  border-radius: 10px;
  @media (max-width: 767px) {
    height: 100%;
  }
}

#call_modal {
  @media (max-width: 767px) {
    position: fixed;
    z-index: 19;
    top: 50px;
    right: 0;
    min-width: 300px;
    max-width: 88%;
    width: fit-content;
    width: -moz-fit-content;
    background: #fff;
    padding: 15px;
    padding-left: 40px;
    border-radius: 15px 0 0 15px;
    -webkit-box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.37);
    box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.37);
    transform: translate(calc(100% - 40px), 0);
    transition: transform .3s ease-in;
  }
}

#call_modal .hide-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  bottom: 0;
}

#call_modal .hide-control:before {
  content: '';
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
  background: var(--color-primary);
  height: 35px;
  width: 3px;
  border-radius: 5px;
}

#call_modal.active .hide-control {
  width: 30px;
}

#call_modal.active .hide-control:before {
  left: 15px;
}

#call_modal.active {
  padding-left: 30px;
  transform: translate(0, 0);
}

@media (min-width: 767px) {
  #call_modal .hide-control {
    display: none;
  }
}

</style>