import {createRouter, createWebHistory} from "vue-router";
import TheDashboard from "@/views/WorkingPage.vue";
import WorkStart from "@/modules/hra/components/WaitingPage.vue";
import TheLogin from "@/views/LoginPage.vue";
import AccountPage from "@/views/AccountPage.vue";
import HistoryPage from "@/views/HistoryPage.vue";
import WelcomePage from "@/views/WelcomePage.vue";
import HRAForm from "@/views/HRAFormPage.vue";
import roleGuard from "./middleware/role-guard";

const routes = [
    { path: "/:catchAll(.*)", redirect: "/login" },
    {
        path: "/login",
        name: "TheLogin",
        component: TheLogin,
    },
    {
        path: "/welcome",
        name: "Welcome",
        component: WelcomePage,
    },
    {
        path: "/work",
        name: "WorkingPage",
        component: TheDashboard,
        meta: {
            accessLevel: 6,
            authorizedOnly: true,
        },
        children: [
            {
                path: '',
                name: "WaitingPage",
                component: WorkStart,
                meta: {
                    accessLevel: 6,
                    authorizedOnly: true,
                },
            },
            {
                path: '/hra',
                name: "HRAForm",
                component: HRAForm,
                meta: {
                    accessLevel: 6,
                    authorizedOnly: true,
                },
            },
            {
                path: "/history",
                name: "History",
                component: HistoryPage,
                meta: {
                    accessLevel: 6,
                    authorizedOnly: true,
                },
            },
        ],
    },
    {
        path: "/account",
        name: "AccountPage",
        component: AccountPage,
        meta: {
            accessLevel: 6,
            authorizedOnly: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

roleGuard(router);

export default router;
