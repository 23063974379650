import {computed} from 'vue' 
import { required, minLength, helpers, sameAs, maxLength } from '@vuelidate/validators';
const loginFormRules = computed(() => { 
    return {
            phone: { required: helpers.withMessage('Please enter your phone number', required), minLength: minLength(10) },
            agree: { required: helpers.withMessage('Agreement is required', sameAs(true)) },
            code: { required: helpers.withMessage('Enter code from SMS you received', required), minLength: minLength(4), maxLength: maxLength(4) },
            
            $validationGroups: {
              phone: ['phone', 'agree'],
              code: ['code']
            }
        }
})

export default loginFormRules