<template>
  <div>
    <div class="check_box-wrap">
      <input type="checkbox" :id="`${name}`" :name="name" />
      <label class="box" :for="`${name}`">
        {{ label }}
      </label>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped>
.box {
  position: relative;
}

label {
  padding-left: 37px;
}

.box:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  content: "";
  width: 25px;
  height: 25px;
  margin: auto 0;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}

input {
  opacity: 1;
  width: 0;
  height: 0;
}

.check_box-wrap {
  display: flex;
  align-items: center;
  height: 25px;
  margin-bottom: 20px;
}

.check_box-wrap:last-child {
  margin-bottom: 0;
}

input:checked + label.box:before {
  background: url("@/modules/hra/img/ic_radio-active.png") no-repeat center;
}
</style>