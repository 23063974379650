<template>
  <login-form />
</template>

<script>
import LoginForm from "@/modules/user/components/LoginForm.vue";
import { defineComponent, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/user";
export default defineComponent({
  components: { LoginForm },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();

    /**
     * Check if user is already logged in relocate him to WaitingPage
     */
    onBeforeMount(() => {
      if (userStore.token) {
        router.push({ name: "WaitingPage" });
      }
    });
  },
});
</script>

<style>
.container {
  max-width: 1366px;
  height: 100%;
  margin: 0 auto;
}
</style>