<template>
  <div class="header-container d-flex ai-c jc-sb">
    <h1 class="title">History</h1>
    <div class="total-result d-flex ai-c jc-sb">
      <p class="total-results-text">Total results</p>
      <base-round-chart
        class="round"
        :color="'#29ABE2'"
        :value="props.value"
        :width="100"
      />
    </div>

<!--    <Datepicker v-model="date" monthPicker :format="'MMMM yyyy'"></Datepicker>-->
  </div>
</template>
<script>
import { defineComponent, ref, watchEffect } from "vue";
import BaseRoundChart from "@/components/base/BaseRoundChart.vue";

export default defineComponent({
  components: { BaseRoundChart },
  props: {
    value: Number
  },
  setup(props) {
    const date = ref('')
    watchEffect(() => {
      console.log('date.value')
      console.log(date.value)
    })
    return { props, date };
  },
});
</script>

<style scoped lang="scss">
.header-container {
  padding: 50px;
}

.title {
  font-size: 5.5em;
  @media (max-width: 1000px) {
    font-size: 3.5em;
  }
}

.total-results-text {
  font-size: 22px;
  @media (max-width: 1000px) {
    margin-left: 50px;
    font-size: 14px;
  }
}
.dp__input {
  background-color: #F1F1F1 ;
  border: none;
  padding: 15px 15px 15px 45px;
  border-radius: 12px;
}

</style>