<template>
  <div>
    <div class="overlay" @click="$emit('close')" :class="{ triggered: triggered, callAlarm: callAlarm }"></div>
    <div class="modal-window">
      <div class="modal-content-container">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue"

export default defineComponent({

  props: {
    triggered: Boolean,
    callAlarm: Boolean
  },
  /**
   * use 'close' emit to close base modal window
   */
  emits: ['close'],
})

</script>

<style lang="scss">

.overlay {
  background: #FFFFFF;
  opacity: 0.76;
  filter: blur(23.5px);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.triggered {
  background: rgba(32, 180, 114, 0.6);

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border: 1px solid white;
    animation-name: pulse;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    box-shadow: 0 0 10px 100px rgb(32, 169, 108);
  }

  &:after {
    content: "";
    position: absolute;
    inset: 0;
    border: 1px solid white;
    animation-name: pulse;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 1s;
    box-shadow: 0 0 10px 100px rgb(32, 169, 108);
  }
}



.callAlarm {
  background: rgba(243, 19, 19, 0.6);

  &::before {
    box-shadow: 0 0 10px 100px rgb(248, 19, 19);
  }

  &:after {
    box-shadow: 0 0 10px 100px rgb(248, 19, 19);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }

  30% {
    opacity: 0.2;
  }

  60% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }

  100% {
    transform: scale(2.3);
    opacity: 0;
  }
}
.green {
  background: #79c56e;
}

.modal-window {
  z-index: 11;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(250, 250, 250, 0.9);
}

.modal-content-container {
  padding: 76px 64px;
  width: max-content;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 10px 37px rgba(0, 0, 0, 0.08);
  background-color: var(--color-white);

  @media (max-width: 450px) {
    padding: 76px 40px;
  }
}
</style>