import axios from "axios";
import qs from "qs";
import {useUserStore} from "@/store/user";

const url = process.env.VUE_APP_API_URL;

export async function getUserCallHistory(data) {
    const userStore = useUserStore()

    const headersAuthorized = {
        Authorization: "Bearer " + userStore.token,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    }

    return axios
        .post(url + "nurse/call_history/list", qs.stringify(data), {
            headers:headersAuthorized,
        })
        .then((r) => r);
}
export async function getUserCallHistoryByDay(day_id=74) {
    const userStore = useUserStore()

    const headersAuthorized = {
        Authorization: "Bearer " + userStore.token,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    }

    return axios
        .post(url + `nurse/call_history/get_current_working/${day_id}`, {},
            { headers: headersAuthorized })
        .then((r) => r);
}