import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    name: "",
    email: "",
    dob_day: "",
    dob_month: "",
    dob_year: "",
    id: "",
    phone: "",
    photo: "",
    sex: "",
    status_id: "",
    type_id: "",
    type_name: "",
    token: "",
    uri: "",
    disActiveLink: false
  }),
  persist: true,
  getters: {
    // get computed properties from state
    getDOB: (state) => state.dob_day + "/" + state.dob_month + "/" + state.dob_year,
    getDisActiveLink: (state) => state.disActiveLink,
  },
  // methods from change state
  actions: {
    setUserData(data) {
      // TODO пройтись циклом
      this.name = data.name;
      this.email = data.email;
      this.dob_day = data.dob_day;
      this.dob_month = data.dob_month;
      this.dob_year = data.dob_year;
      this.last_name = data.last_name;
      this.id = data.id;
      this.phone = data.phone;
      this.photo = data.photo;
      this.sex = data.sex;
      this.status_id = data.status_id;
      this.type_id = data.type_id;
      this.type_name = data.type_name;
      this.uri = data.uri;
      this.token = data.token;
    },
    setDisActiveLink (param) {
      this.disActiveLink = param;
    }
  },
});
