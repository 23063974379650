import axios from "axios";
import qs from "qs";
import {useUserStore} from "@/store/user";
const url = process.env.VUE_APP_API_URL;


/**
 *  Get patient HRA data
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_hra_init(data = {}) {
    const userStore = useUserStore()

    const headersAuthorized = {
        Authorization: "Bearer " + userStore.token,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    }

    return axios
        .post(url + "hra/init", qs.stringify(data), {
            headers: headersAuthorized,
        })
        .then((r) => r);
}

/**
 *  Set patient HRA data
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_hra_confirm(user_id, patient_id, data = {}) {
    const userStore = useUserStore()

    const headersAuthorized = {
        Authorization: "Bearer " + userStore.token,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    }

    data['user_id'] = user_id;
    data['patient_id'] = patient_id;

    return axios
        .post(url + "hra/confirm", qs.stringify(data), {
            headers: headersAuthorized,
        })
        .then((r) => r);
}
