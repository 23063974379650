import { useUserStore } from "@/store/user";
const roleGuard = (router) => {
  router.beforeEach((to) => {
    const userStore = useUserStore();
    const type_id = userStore.type_id;
    const isAuthorized = userStore.token ? true : false;
    const isHasAccess = type_id <= to.meta.accessLevel && isAuthorized;
    const publicPage = !to.meta.authorizedOnly;

    if (publicPage) {
      return true;
    } else if (isHasAccess) {
      return true;
    } else if (!isHasAccess && isAuthorized) {
      if (to.name !== "AccessDenied") return { name: "AccessDenied" };
    } else {
      if (to.name !== "TheLogin") return { name: "TheLogin" };
    }
  });
};

export default roleGuard;
