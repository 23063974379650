const temporaryData = {
  headers: [
    { text: "Date & Time", value: "date", sortable: true },
    { text: "Call Duration", value: "call_duration", sortable: true },
    { text: "Patient Name", value: "patient_name", sortable: true },
  ],
  items: [
    {
      patient_name: "Curry",
      date: "09/04.2022 12:46",
      call_duration: 122,
      id: 20,
    },
    {
      patient_name: "Curry",
      date: "09/10.2022 12:46",
      call_duration: 3,
      id: 12,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
    {
      patient_name: "Curry",
      date: "09/03.2022 12:46",
      call_duration: 77,
      id: 13,
    },
    {
      patient_name: "John",
      date: "21/3.2022 12:46",
      call_duration: 12,
      id: 15,
    },
  ],
};

export default temporaryData;
