import { createApp } from "vue";
import "vue3-tel-input/dist/vue3-tel-input.css";
import Vue3TouchEvents from "vue3-touch-events";
import VueTelInput from "vue3-tel-input";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import VueApexCharts from "vue3-apexcharts";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import router from "./router/index.js";
import App from "./App.vue";
import Notifications from "@kyvg/vue3-notification";
import "./assets/scss/index.scss"
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import baseComponentsArray from "@/components/base/base-components-array";
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const telInputOptions = {
  autoDefaultCountry: true,
  defaultCountry: "US",
  ignoredCountries: ["RU"],
  inputOptions: {
    autofocus: true,
    showDialCode: false,
  },
  dropdownOptions: {
    showDialCodeInSelection: false,
    showSearchBox: true,
  },
  preferredCountries: ["US"],
  validCharactersOnly: true,
};

const app = createApp(App);

app.use(router);
app.use(pinia);
app.use(VueTelInput, telInputOptions);
app.use(Notifications);
app.use(VueApexCharts);
app.use(Vue3TouchEvents);
baseComponentsArray.forEach(({ name, component }) => {
    app.component(name, component);
});
app.component("TheHeader", TheHeader);
app.component("TheFooter", TheFooter);
app.component("Datepicker", Datepicker);
app.component("DataTable", Vue3EasyDataTable);

app.mount("#app");
