<template>
  <div class="container pb-100">
    <history-header :value="callHistory.length" />
    <history-table
      class="pb-100"
      :dataHeaders="tableHeaders"
      :dataItems="callHistory"
    />
  </div>
</template>
<script>
import {defineComponent, onBeforeMount, ref} from "vue";
import HistoryHeader from "@/modules/hra/components/HistoryHeader.vue";
import HistoryTable from "@/modules/hra/components/HistoryTable.vue";
import temporaryData from "@/modules/hra/services/temporary-data";
import {getUserCallHistory} from "@/API/API-history";
// import moment from 'moment';
export default defineComponent({
  components: { HistoryHeader, HistoryTable },
  setup() {
    const tableHeaders = ref([
      { text: "Date & Time", value: "start_time", sortable: true },
      { text: "Status", value: "status", sortable: true },
      { text: "Call Duration", value: "duration", sortable: true },
      { text: "Patient Name", value: "patient", sortable: true },
    ])
    const callHistory = ref([])
    onBeforeMount(()=> {
      getUserCallHistory().then(r => {
        callHistory.value = r.data.response.data
        console.log(callHistory.value)
        callHistory.value.forEach(i=> {
          i.start_time =  i.start.date + ' ' + i.start.time
        })
        console.log(callHistory.value)
      })

    })
    return { tableHeaders, callHistory, temporaryData };
  },
});
</script>
