import { defineStore } from "pinia";

export const useHraStore = defineStore("hra", {
    state: () => ({
        state: {}
    }),
    // methods from change state
    actions: {
        setState(data) {
            this.state = data;
        },
    },
});
