export default {
  HIDE_INVITE_MORE_HEADER: true,
  DEFAULT_LOGO_URL: "",
  SHOW_WATERMARK_FOR_GUESTS: false,
  SHOW_BRAND_WATERMARK: false,
  JITSI_WATERMARK_LINK: "",
  SHOW_JITSI_WATERMARK: true,
  DEFAULT_BACKGROUND: "#1d1e22",
  TOOLBAR_BUTTONS: ["microphone", "camera", "fullscreen"],
  SHOW_CHROME_EXTENSION_BANNER: false,
  SETTINGS_SECTIONS: ["devices", "moderator"],
  MOBILE_APP_PROMO: true,
  LANG_DETECTION: true,
  DEFAULT_REMOTE_DISPLAY_NAME: "Patient",
  DEFAULT_LOCAL_DISPLAY_NAME: "You",
};
