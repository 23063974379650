export function useSockets(callbacks) {
    const serverUrl = process.env.VUE_APP_SOCKET_URL;
    const token = localStorage.getItem("token")

    const Socket = {
        conn: null,
        connect: () => {
            let connection = new WebSocket(serverUrl)
            Socket.isClosed = false

            connection.onopen = () => {
                Socket.conn = connection;
                console.log(connection.readyState);
                Socket.init();

                connection.onmessage = (e) => {
                    callbacks.onmessage(e)
                };
                connection.onclose = () => {
                    console.log('close');
                    if(!Socket.isClosed)
                        Socket.reconnect()
                }

                setInterval(() => {
                    Socket.sessionUpdate()
                }, 60000)
            }
        },
        reconnect: () => {
            console.log('RECONNECTING');
            Socket.connect()
        },
        init: () => {
            console.log('socket init');

            Socket.conn.send(
                JSON.stringify({
                    action: "init",
                    data: { key: token },
                })
            );
        },
        sessionOpen: () => {
            Socket.conn.send(
                JSON.stringify({
                    action: "session_open",
                    data: {},
                })
            );
        },
        sessionClose: () => {
            Socket.conn.send(
                JSON.stringify({
                    action: "session_close",
                    data: {},
                })
            );
        },
        sessionPause: () => {
            Socket.conn.send(
                JSON.stringify({
                    action: "session_pause",
                    data: {},
                })
            );
        },
        sessionUnPause: () => {
            Socket.conn.send(
                JSON.stringify({
                    action: "session_unpause",
                    data: {},
                })
            );
        },
        callConfirm: () => {
            Socket.conn.send(
                JSON.stringify({
                    action: "call_confirm",
                    data: {},
                })
            );
        },
        callDecline: () => {
            Socket.conn.send(
                JSON.stringify({
                    action: "call_decline",
                    data: {},
                })
            );
        },
        callUpdate: (state_val) => {
            Socket.conn.send(
                JSON.stringify({
                    action: "call_update",
                    data: state_val,
                })
            );
        },
        callUpdateRequest: () => {
            Socket.conn.send(
                JSON.stringify({
                    action: "call_update_request",
                    data: {},
                })
            );
        },
        callClose: () => {
            Socket.conn.send(
                JSON.stringify({
                    action: "call_close",
                    data: {},
                })
            );
        },
        sessionUpdate: () => {
            Socket.conn.send(
                JSON.stringify({
                    action: "working_time",
                    data: {},
                })
            );
        },
    }

    return Socket
}
