import { defineStore } from "pinia"

export const useWorkingSessionStore = defineStore("workingSession", {
  state: () => ({
    isActive: false,
    isPaused: false,
    scheduleId: 0,
    workingTimeHistory: {
      today: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      month: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    },
    callsHistory: {
      today: 0,
      month: 0,
    },
  }),
  persist: true,
  // methods from change state
  getters: {
    getScheduleId: (state) => state.scheduleId,
    getWorkingTimeHistoryToday: (state) => state.workingTimeHistory.today,
    getWorkingTimeHistoryMonth: (state) => state.workingTimeHistory.month,
    getCallsHistoryToday: (state) => state.callsHistory.today,
    getCallsHistoryMonth: (state) => state.callsHistory.month,
  },
  actions: {
    setScheduleId(value) {
      this.scheduleId = value
    },
    setActive(value) {
      this.isActive = value
    },
    setPaused(value) {
      this.isPaused = value
    },
    setWorkingTimeHistory(today, month) {
      this.workingTimeHistory.today = today
      this.workingTimeHistory.month = month
    },
    setCallsHistory(today, month) {
      this.callsHistory.today = today
      this.callsHistory.month = month
    },
  },
})
