<template>
  <button>
    <img :src="logoutSvg" alt="logout" class="logout-icon" />
    <slot> </slot>
  </button>
</template>

<script>
import { defineComponent } from "vue";
import logoutSvg from "../../modules/user/assets/img/logout.svg";

export default defineComponent({
  setup() {
    return {
      logoutSvg,
    };
  },
});
</script>

<style lang="scss">
.btn {
  cursor: pointer;
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  line-height: 0.75rem;
  border-radius: 0.75rem;
  padding: 1.375rem;
  transition: 0.4s;
}

.appstore {
  border: 0.8px solid #d0d0d0;
  border-radius: 10px;
}

.logout-icon {
  display: none;
  margin-right: 10px;
}

.logout .logout-icon {
  display: block;
}

.primary {
  border: 1px solid var(--color-primary);
  background: var(--color-primary);
  color: var(--color-white);

  &:hover {
    background: transparent;
    color: var(--color-primary);
  }
}

.start {
  background: var(--color-success);
  color: var(--color-white);
}

.outlined-primary {
  background: var(--color-white);
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.outlined-primary:hover {
  background: var(--color-primary);
  color: var(--color-white);
  border: 1px solid var(--color-primary);
}

.success {
  background: var(--color-success);
  color: var(--color-white);
}

.outlined-success {
  background: var(--color-white);
  color: var(--color-success);
  border: 1px solid var(--color-success);
}

.outlined-danger {
  background: #fc6287;
  color: var(--color-white);
}

.btn.danger {
  background: var(--color-white);
  color: var(--color-red);
  border: 1px solid var(--color-red);

  &:hover {
    background: var(--color-red);
    color: white;
  }
}

.logout img {
  transition: 0.3s;
}

.logout {
  display: flex;
  align-items: center;
  padding: 22px 60px;
  border: 1px solid #dedede;
  border-radius: 12px;
  color: #ff5151;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    border-color: #ff5151;

    img {
      transform: translateX(-10px);
    }
  }
}
</style>