<template>
  <div class="d-flex wrapper">

    <div class="leftPart">
      <h2 class="text-main">Healeze</h2>
      <p class="p-text">The future of preventative healthcare.</p>

      <div class="span-two">
        <base-button-app-store class="btn appstore" :text1="'Download on the'" :text2="'App Store'" :image="0"/>
        <base-button-app-store class="btn appstore" :text1="'Get it on'" :text2="'Google Play'" :image="1"/>
      </div>

      <base-button class="btn start mt-20 take-assess">Take Your Health Risk Assessment Now</base-button>
    </div>

    <div class="rightPart">
      <img :src="WelcomeIMG" alt="WelcomeIMG">
    </div>
  </div>

</template>

<script>

import { defineComponent } from "vue";
import BaseButton from "@/components/base/BaseButton";
import BaseButtonAppStore from "@/components/base/BaseButtonAppStore";
import WelcomeIMG from '../../../assets/images/welcome.png'

export default defineComponent({
  components: {BaseButtonAppStore, BaseButton},
  setup() {
    return {
      WelcomeIMG
    };
  },
});

</script>

<style scoped lang="scss">

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 80px;
  padding: 40px 0 0;
}

.span-two {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px;
}

.text-main {
  font-style: normal;
  font-weight: 400;
  font-size: 126px;
  line-height: 152px;
  color: #29ABE2;
}

.p-text {
  padding-bottom: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #7B7B7B;
}

@media (max-width: 992px) {

  .wrapper {
    padding: 40px 0 60px;
  }
}

@media (max-width: 768px) {

  .text-main {
    font-size: 50px;
    line-height: 60px;
    color: #29ABE2;
  }

  .wrapper {
    display: block!important;
  }

  .rightPart {
    display: none;
  }
}

@media (max-width: 576px) {
  .span-two {
    display: grid;
    justify-items: start;
    grid-template-columns: auto;
    gap: 20px;
  }
}

@media (max-width: 576px) {
  .appstore, .take-assess {
    margin: 0 auto;
    width: 200px;
  }

  .span-two {
    padding-bottom: 30px;
  }

  .take-assess {
    line-height: 18px;
  }

  .wrapper {
    text-align: center;
  }
}

</style>