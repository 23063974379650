export default [
  {
    text: "support@laworq.com",
    link: "#",
  },
  {
    text: "Privacy Policy",
    link: "#",
  },
  {
    text: "Terms of Use",
    link: "#",
  },
  {
    text: "How we works",
    link: "#",
  },
  {
    text: "About",
    link: "#",
  },
  {
    text: "FAQ",
    link: "#",
  },
];
