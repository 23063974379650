<template>
    <div class="hra">
      <section v-for="section in form_config" :key="section">
        <div class="row">
          <template v-for="col in section" :key="col">
            <div :class="col.class">
              <!--html level section-->
              <div v-html="col.html"></div>

              <div class="row">
                <template v-for="group in col.input_groups" :key="group">
                  <div :class="group.class">
                    <!--html level input group-->
                    <div v-html="group.html"></div>

                    <div class="row">
                      <template
                        v-for="inputRow in group.inputs"
                        :key="inputRow"
                      >
                        <div :class="inputRow.class">
                          <div class="row">
                            <template v-for="input in inputRow" :key="input">
                              <div :class="input.class">
                                <template v-if="
                                    input.type === 'text' ||
                                    input.type === 'email'">
                                  <InputText
                                    @input="(val) => { state[input.name] = val; this.$emit('update-state', state) }"
                                    :name="input.name"
                                    :input-value="state[input.name]"
                                    :is-error="v$.value[input.name]?.$error"
                                    :placeholder="input.placeholder"
                                  />

                                  <div v-if="v$.value[input.name]?.$error" class="color-red">{{ v$.value[input.name].$errors[0].$message }}</div>
                                </template>

                                <template v-else-if="input.type === 'radio'">
                                  <div>
                                    <InputRadio
                                      @input="(val) => { state[input.name] = val; this.$emit('update-state', state) }"
                                      :value="input.value"
                                      :input-value="state[input.name]"
                                      :name="input.name"
                                      :label="input.label"
                                    />
                                  </div>
                                </template>

                                <template v-else-if="input.type === 'checkbox'">
                                  <InputCheckBox
                                      @update:checked="(val) => { state[input.name] = val; this.$emit('update-state', state) }"
                                      :checked="state[input.name]"
                                      :name="input.name"
                                      :label="input.label"
                                  />
                                  <div v-if="v$.value[input.name]?.$error" class="color-red">{{ v$.value[input.name].$errors[0].$message }}</div>
                                </template>

                                <template v-else-if="input.type === 'tel'">
                                  <InputText
                                      @input="(val) => { state[input.name] = val; this.$emit('update-state', state) }"
                                      :name="input.name"
                                      :input-value="state[input.name]"
                                      :is-error="v$.value[input.name]?.$error"
                                      :placeholder="input.placeholder"
                                  />
                                  <div v-if="v$.value[input.name]?.$error" class="color-red">{{ v$.value[input.name].$errors[0].$message }}</div>
                                </template>

                                <template v-else-if="input.type === 'submit'">
                                  <div
                                    class="btn btn-primary"
                                    @click="submitForm()"
                                    :class="input.class"
                                  >{{ input.label }}</div>
                                </template>

                                <template v-else>
                                  <div class="input-group">
                                    <h5 style="color: red">ERROR</h5>
                                  </div>
                                </template>
                              </div>
                            </template>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>

                  <!-- TODO може бути херня) -->
                  <div v-if="group['inputs'][0][0]?.type === 'radio'">
                    <div
                      v-if="v$.value[group['inputs'][0][0]?.name]?.$error"
                      class="color-red"
                    >
                      {{
                        v$.value[group["inputs"][0][0]?.name].$errors[0]
                          .$message
                      }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </section>

      <!-- <div class="btn primary mt-30 mb-90" @click="submitForm()">Submit</div> -->
    </div>
</template>

<script>
import { defineComponent, ref, watchEffect } from "vue";
import useValidate from "@vuelidate/core";
import InputText from "@/modules/hra/components/InputText";
import InputRadio from "@/modules/hra/components/InputRadio";
import InputCheckBox from "@/modules/hra/components/InputCheckBox";
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import parseValidationRules from '@/plugins/form_config/utils/JSONFormParseValidationRules'
import { API_hra_confirm } from '@/API/API-hra'
import { useMeetStore } from '@/store/meet'

export default defineComponent({
  components: { InputText, InputRadio, InputCheckBox },
  props: {
    config: Object,
    state_updated: Object
  },
  emits: ['update-state'],
  setup(props) {
    const state = ref({
      // 'first-name': '',
      // 'last-name': '',
      //  ...
      //  ...
    })
    const rules = ref({
      // 'first-name': { required },
      // 'last-name': { required, minLength: minLength(2) },
      //  ...
      //  ...
    })

    const form_config = ref({})

    const v$ = ref({});

    v$.value = useValidate(rules, state);

    watchEffect(() => {
      state.value = props.state_updated;
    })

    watchEffect(() => {
      if(props.config.state) {
        state.value = props.config.state
        rules.value = parseValidationRules(props.config.validators)
        form_config.value = props.config.config

        v$.value = useValidate(rules, state)
      }
    })


    function submitForm() {

      const meetStore = useMeetStore()

      v$.value.value.$validate() // checks all inputs
      if (!v$.value.value.$error) {

        API_hra_confirm(meetStore.getPatientUserId, meetStore.getPatientId, state.value).then((res) => {
          console.log(res);
        })

      } else {
        console.log('error')
      }
    }


    return { state, form_config, v$, submitForm }
  }
})

</script>

<style scoped>

</style>